import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import CountryContent from 'components/country';

import replaceVariablesFromPrismic from 'utils/functions/replaceVariablesFromPrismic';
import replaceLocalePhoneNumber from 'utils/functions/replaceLocalePhoneNumber';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const Country = ({ location, pageContext, data, preview }) => {
  const fsExcludeFlag = pageContext && pageContext.fs_exclude_flag

  useEffect(() => {
    localStorage.setItem("excludeFS", fsExcludeFlag)
  }, [])

  const language = preview ? data.prismicCountry.lang : pageContext.lang
  const alternateLanguages = preview ? data.prismicCountry.alternate_languages : pageContext.alternateLanguages;
  const prismicPageContextData = !preview && pageContext.prismicData;

  const countryIndexPageUrl = filterPrismicDataByLanguage(data.allPrismicCountryHome.edges, language);
  
  const filterLocaleAirfieldProps = filterPrismicDataByLanguage(data.allPrismicPropertiesCountry.edges, language)

  const countryProps = filterLocaleAirfieldProps.node.data;
  const phoneNumber = replaceLocalePhoneNumber(data.allPrismicUniversalPhoneNumber.edges);

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  //Hero Image default banner image
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  const countryProperties = filterPrismicDataByLanguage(data.allPrismicPropertiesCountry.edges, language);

  const hasWidget = preview ? data?.allPrismicWidgetData?.edges?.length > 0 
  : countryProperties?.node?.data?.body?.length > 0;

  const widgetData = preview ? data?.allPrismicWidgetData?.edges : countryProperties?.node?.data?.body;

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  const checkPreview = () => {
    let headData;

    if (!preview) {

      if (prismicPageContextData) {
        headData = {
          meta_title: prismicPageContextData?.data?.meta_title,
          meta_description: prismicPageContextData?.data?.meta_description,
          meta_keywords: prismicPageContextData?.data?.meta_keywords,
        } 
      } else {
        const replacedData = replaceVariablesFromPrismic(countryProps, phoneNumber, null, pageContext.country_name, null)

        headData = {
          meta_title: replacedData.title,
          meta_description: replacedData.description,
          meta_keywords: replacedData.keywords,
        }
      }

      // add open graph meta data if available
      if(prismicPageContextData?.data?.body2?.length > 0) {
        const filteredOpenGraphData = prismicPageContextData.data.body2.filter(el => el.slice_type === "open_graph");

        if (filteredOpenGraphData?.length > 0) {
          const openGraphData = filteredOpenGraphData[0]?.primary;

          if (openGraphData?.og_title) {
            headData = {
              ...headData,
              og_title: openGraphData.og_title,
              og_description: openGraphData?.og_description || openGraphData?.og_title,
              og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
              og_type: openGraphData?.og_type || 'website',
            }
          }
        }
      }

      return {
        content: pageContext,
        countryID: pageContext.id.replace(/\D/g, ""),
        slices: prismicPageContextData?.data?.body || null,
        richText: prismicPageContextData?.data?.post?.raw || null,
        heroImage: data.prismicCountry ? data.prismicCountry.data.hero_image : null,
        headData: headData
      } 
    } 
    
    else {
      const headData = {
        meta_title: data.prismicCountry.data.meta_title,
        meta_description: data.prismicCountry.data.meta_description,
        meta_keywords: data.prismicCountry.data.meta_keywords,
      }

      return {
        content: data.prismicCountry,
        countryID: data.prismicCountry.uid,
        slices: data.prismicCountry.data.body,
        richText: data.prismicCountry.data.post,
        heroImage: data.prismicCountry.data.hero_image,
        headData: headData
      }
    }
  }

  const { content, countryID, slices, richText, heroImage, headData } = checkPreview();

  const bannerImageSlice = slices && slices.find(el => el.slice_type === "banner_image")
  // TODO need to refactor to table_of_contents
  const tableOfContentsSlice = slices && slices.filter(el => el.slice_type === "page_content_navigation");

  return (
    <RenderPostWrapper 
      heroImage={heroImage}
      preview={preview}
      meta={headData}
      location={location}
      language={language}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      fsExcludeFlag={fsExcludeFlag}
      showFlightSearchWidget={false}
      widget={hasWidget ? widgetData : []}
      bannerImageSlice={bannerImageSlice}
      tableOfContentsSlice={tableOfContentsSlice}
      component={
        <CountryContent
          content={content}
          fsExcludeFlag={fsExcludeFlag}
          richContent={richText}
          preview={preview}
          location={location}
          airfields={!preview ? pageContext.airfields : null}
          slices={slices ? slices : null}
          countryID={countryID}
          countryProps={countryProps}
          countryIndexPageUrl={countryIndexPageUrl.node.data.url}
          language={language}
          phoneNumber={phone_local}
        />
      }
    />
  );
}

Country.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export default Country

export const CountryQuery = graphql`
  query countryQuery($uid: String!) {
    prismicCountry(uid: { eq: $uid }) {
      uid
      lang
      first_publication_date
      last_publication_date
      data {
        hero_image {
          url
          alt
        }
      }
    }
    allPrismicPropertiesCountry {
      edges {
        node {
          ...PrismicPropertiesCountry
        }
      }
    }
    allPrismicCountryHome {
      edges {
        node {
          lang
          data {
            url
          }
        }
      }
    }
    allPrismicPropertiesCountry {
      edges {
        node {
          id
          lang
          data {
            should_show_flight_search
            body {
              primary {
                widget_document {
                  document {
                    data {
                      widget_type
                      widget_title {
                        text
                      }
                      widget_text {
                        raw {
                          text
                          type
                        }
                      }
                      widget_button_text
                      widget_button_link {
                        url
                        target
                      }
                      phone_number
                      card_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`